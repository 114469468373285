import React from "react";
import { Typography, Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  smartContract: {
    color: "#311499",
    fontWeight: 500,
    fontSize: 22,
    paddingBottom: 0,
    paddingLeft: "10px",
    [theme.breakpoints.down('xs')]:{
      display:"none",
    },
  },
  logoBox:{
  display: "flex",
  // marginTop: "-20px",
  [theme.breakpoints.down('xs')]:{
    // marginTop: "0px",
  },
},

}));

const Logo = (props) => {
  const classes = useStyles();

  return (
    <Link to="/" style={{    textDecoration: "none",}}>
    <Box
      className={classes.logoBox}
      alignItems="center"
    >
     
        <img
          className={classes.img}
          src="images/Logo.png"
          alt="Logo"
          {...props}
        />
    
      <Typography className={classes.smartContract}>SMART TECH</Typography>
    </Box>
    </Link>
  );
};

export default Logo;
