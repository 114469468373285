import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/Config/APIconfig";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";


export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("creatturAccessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Creattur ${accessToken}`;
  } else {
    localStorage.removeItem("creatturAccessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("creatturAccessToken");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const history = useHistory();

  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({});

  const accessToken = window.localStorage.getItem("creatturAccessToken");
  useEffect(() => {
    axios
      .get(ApiConfig.getProfile, {
        headers: {
          token: accessToken,
        },
      })
      .then((response) => {
        if (response.data.response_code === 200) {
          console.log(response);
          localStorage.setItem("Profile", response.data.result.profilePic);
          setUserData({
            profilePic: response.data.result.profilePic,
          });
        } else  if(response.data.response_code === 404){
          localStorage.removeItem("creatturAccessToken")
          localStorage.removeItem("tokenNew")
          history.push('/')
        }else{
          localStorage.removeItem("creatturAccessToken")
          localStorage.removeItem("tokenNew")
          history.push('/')

          // toast.error(response.data.response_message)
        }
        // setIsLoading(false);
      })
      .catch((response) => {
        // setIsUpdating(false);
        console.log("response", response);
      });
  }, []);

  let data = {
    userLoggedIn: isLogin,
    userData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
